import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';

export type TrialSubscription = Stripe.Subscription;
export type TrialSubscriptions = TrialSubscription[];

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED',
}

type TrialsState = {
  status: Status;
  data: TrialSubscriptions;
}

const initialState: TrialsState = {
  status: Status.INITIAL,
  data: [],
};

const trialsSlice = createSlice({
  name: 'trialSubscriptions',
  initialState: initialState,
  reducers: {
    updateTrials: (state, action: PayloadAction<{ subscriptions: TrialSubscriptions }>) => {
      const { subscriptions } = action.payload;

      state.data = subscriptions;
      state.status = Status.UPDATED;
    },
    updateTrialsFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetTrials: (state) => {
      state.data = [];
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updateTrials,
  updateTrialsFailed,
  resetTrials,
} = trialsSlice.actions

export default trialsSlice.reducer;

