type Environment = 'test' | 'production';
enum ProductName {
    ROUTES = 'ROUTES',
    PAYROLL = 'PAYROLL',
}

const productIds: Record<Environment, Record<ProductName, string>> = {
    test: {
        [ProductName.ROUTES]: 'prod_ELcef9CkHO68gI',
        [ProductName.PAYROLL]: 'prod_QzeoyGU2wu70Nx',
    },
    production: {
        [ProductName.ROUTES]: 'prod_Egey6JZNCm6xlM',
        [ProductName.PAYROLL]: 'prod_R4UGzj76y5Q7QO',
    },
};

const currentEnvironment: Environment = process.env.NODE_ENV === 'production' ? 'production' : 'test';

export const StripeProductId = {
    get ROUTES(): string {
        return productIds[currentEnvironment][ProductName.ROUTES];
    },
    get PAYROLL(): string {
        return productIds[currentEnvironment][ProductName.PAYROLL];
    },
};
