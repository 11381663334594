import React from 'react';
import { useColorPack } from '@hooks/redux';
import './LineItems.css';

type Item = {
  description: string | null;
  percentValue?: number;
  amount?: number;
};

type Props = {
  title: string;
  items: Item[];
};

export default function LineItems({ title, items }: Props) {
  const colorPack = useColorPack();

  const getLineItems = (items: Item[]) => {
    return items.map((item) => {
      return (
        <div
          className='LineItem entry'
          style={{ borderTopColor: colorPack.border }}
        >
          <div className='description'>{item.description}</div>
          {getCost(item)}
        </div>
      );
    });
  };

  const getCost = ({ amount = 0, percentValue }: Item) => {
    const textCost = `$${(amount / 100).toFixed(2)}`;
    const isCredit = amount < 0 || percentValue;
    return (
      <div
        className={`cost${isCredit ? ' credit' : ''}`}
        style={{ color: isCredit ? colorPack.green : colorPack.medCont }}
      >
        {percentValue ? `${percentValue}% OFF` : textCost}
      </div>
    );
  };

  return (
    <div>
      <div className='LineItem' style={{ borderTopColor: colorPack.border }}>
        <div className='title'>{title}</div>
      </div>
      {getLineItems(items)}
    </div>
  );
}
