import React from "react";
import Stripe from "stripe";
import SignupInfo from "./SignupInfo";
import { LiveIspProfile } from '@reducers/liveIspList';
import "./PastDueItem.css";

type Subscription = Stripe.Subscription;

type Props = {
  id: string | null;
  profile: LiveIspProfile;
  subscription: Subscription;
  onClick: () => void;
};

export default function PastDueItem(props: Props) {
  const { id, profile, subscription, onClick } = props;

  return (
    <div key={id} className="PastDueItem" onClick={onClick}>
      <p className="cspName">
        {profile.CSPName}{" "}
        <span style={{ color: "#444", fontSize: "12px" }}>
          {profile?.station?.stationID ?? 'No station Id'}
        </span>
      </p>

      <SignupInfo profile={profile} subscription={subscription} />
    </div>
  );
}
