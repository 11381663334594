import ConsoleApiService from "./ConsoleApiService";
import {
  SubscriptionPlanType,
  SubscriptionPlanContract,
  SubscriptionPlanContractDto,
  mapSubscriptionPlanContractDtoToDomain
} from "@models";
import Stripe from "stripe";

type UpdateSubscriptionTypeBody = {
  subscriptionPlanType: SubscriptionPlanType;
  contractSignedDate: string | null;
  contractUri: string | null;
  minimumContractedRoutes: number | null;
  minimumSharedContractedRoutes: number | null;
};

export class ProductSubscriptionContractService {
  constructor(private api: ConsoleApiService) {}

  async getSubscriptionContract(
    operationId: string
  ): Promise<{ subscriptionContract: SubscriptionPlanContract }> {
    const { subscriptionContract } = await this.api.get<{
      subscriptionContract: SubscriptionPlanContractDto;
    }>(`subscription-contracts/${operationId}`);

    return {
      subscriptionContract: mapSubscriptionPlanContractDtoToDomain(
        subscriptionContract
      ),
    };
  }

  async changeSubscriptionType(
    operationId: string,
    subscriptionPlanType: SubscriptionPlanType,
    contractSignedDate: string | null,
    contractUri: string | null,
    minimumContractedRoutes: number | null,
    minimumSharedContractedRoutes: number | null
  ): Promise<{
    subscription: Stripe.Subscription;
    subscriptionContract: SubscriptionPlanContract | null;
  }> {
    const body: UpdateSubscriptionTypeBody = {
      subscriptionPlanType,
      contractSignedDate,
      contractUri,
      minimumContractedRoutes,
      minimumSharedContractedRoutes
    };

    const { subscription, subscriptionContract } = await this.api.patch<{
      subscription: Stripe.Subscription;
      subscriptionContract: SubscriptionPlanContractDto | null;
    }>(`subscription-contracts/${operationId}`, body);

    return {
      subscription,
      subscriptionContract: subscriptionContract
      ? mapSubscriptionPlanContractDtoToDomain(subscriptionContract)
      : null,
    };
  }
}
