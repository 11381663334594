import React from 'react';
import moment from 'moment-timezone';
import Stripe from 'stripe';
import CalendarList from './CalendarList';
import CancellationItem from './CancellationItem';
import ScrollView from '../Page/ScrollView';
import { LiveIspProfile } from '@reducers/liveIspList';
import {
  useCancellingAtPeriodEndSubscriptions,
  useLiveIspList,
} from '@hooks/redux';
import './CancelAtPeriodEnd.css';
import {StripeProductId} from "../../utils/subscriptions/stripeProducts";

type Subscription = Stripe.Subscription;

type DaysAgo = {
  daysAgo: string;
  label: string;
  items: DaysAgoItem[];
}

type DaysAgoItem = {
  id: string | null;
  subscription: Subscription;
  profile: LiveIspProfile;
}

type Props = {
  setFocusedISP: (ispId: string | null) => void;
  lastNDays: number;
}

export default function CancelAtPeriodEnd ({ setFocusedISP, lastNDays }: Props) {
  const cancelAtEndOfPeriod = useCancellingAtPeriodEndSubscriptions();
  const liveISPList = useLiveIspList();

  if (!cancelAtEndOfPeriod) return null;

  const totalValue = cancelAtEndOfPeriod.reduce((acc, subscription) => {
    const quantity  = subscription.items.data.find((item) => item.plan.product === StripeProductId.ROUTES)?.quantity

    acc += ((quantity ?? 0) * 20);

    return acc;
  }, 0);
  const now = Date.now();
  const routes = (cancelAtEndOfPeriod || []).reduce((acc, subscription) => {
    if (!subscription || !subscription.metadata) return acc;

    const { ISPID } = subscription.metadata;
    const profile = liveISPList[ISPID];

    if (!profile) return acc;

    for (const workAreaId in profile.WorkAreas) {
      if (profile.WorkAreas[workAreaId].paid === true) {
        acc.paid += 1;
      }

      acc.total += 1;
    }

    return acc;
  }, { paid: 0, total: 0 });

  return (
    <React.Fragment>
      <div className='cohort'>
        <p style={{ fontSize: '12px' }}>{`ISPs canceling in the next ${lastNDays} days`}</p>

        <p style={{ fontSize: '12px' }}>
          <span style={{ margin: '0 5px', color: 'rgb(22, 142, 50)' }}>${totalValue}</span>
          <span style={{ margin: '0 5px' }}>
            <span style={{ color: '#006DAB' }}>{routes.paid}</span>
            {` / `}
            <span>{routes.total}</span>
          </span>
          <span style={{ margin: '0 5px' }}>{cancelAtEndOfPeriod.length} ISP</span>
        </p>
      </div>

      <ScrollView direction='vertical' height='700px'>
        <CalendarList
          labelColor='#666'
          data={Object.values(cancelAtEndOfPeriod.reduce<Record<string, DaysAgo>>((acc, subscription) => {
            const { current_period_end, metadata } = subscription;
            const { ISPID } = metadata;
            const daysAgo = moment(current_period_end * 1000).fromNow();

            if (!acc.hasOwnProperty(daysAgo)) {
              const label = daysAgo;

              acc[daysAgo] = {
                daysAgo: daysAgo,
                label,
                items: []
              };
            }

            acc[daysAgo].items.push({
              id: ISPID || null,
              subscription: subscription,
              profile: liveISPList[ISPID] || null
            });

            return acc;
          }, {}))}
          onItemClick={setFocusedISP}
          ListItem={CancellationItem}
        />
      </ScrollView>
    </React.Fragment>
  );
}
