import React from 'react';
import Stripe from 'stripe';
import pr from '@packageroute/types-firebase';
import moment from 'moment-timezone';
import './SignupItem.css';

type Subscription = Stripe.Subscription;
type Profile = pr.isp.profile.Profile;

type Props = {
  id: string | null;
  ISPID: string;
  trial: Subscription;
  profile: Profile;
  onClick: () => void;
}

export default function SignupItem (props: Props) {
  const { ISPID, trial, profile, onClick } = props;

  const metadata = profile.metadata || {};

  // @ts-ignore startedSignupAt is not present on Profile type
  const startedSignupAt = metadata.startedSignupAt || null;
  const createdAt = metadata.createdAt || null;
  const startTime = createdAt || startedSignupAt;
  const trialEnding = trial && trial.trial_end && moment(trial.trial_end * 1000).tz(profile.timeZone).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    sameElse: 'MMM Do'
  });

  return (
    <div key={ISPID} className='SignupItem' onClick={onClick}>
      <p>{profile.CSPName} <span style={{ color: '#444', fontSize: '12px' }}>{profile.station && profile.station.stationID}</span></p>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {
          trialEnding
            ? <span style={{ marginRight: '10px', color: '#EA7D14', fontSize: '14px', fontWeight: 400 }}>
              Trial
            </span>
            : null
        }

        <div style={{ width: '60px' }}>
          <span style={{ fontSize: '14px' }}>
            {moment(startTime).format('h:mma')}
          </span>
        </div>
      </div>
    </div>
  );
}
