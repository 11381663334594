import React from 'react';
import moment from 'moment';
import './CustomerSummary.css';
import Stripe from 'stripe';

type Props = {
  subscription?: Stripe.Subscription
}

export default function CustomerSummary ({ subscription }: Props) {
  const trialEndDate = (subscription?.status === 'trialing' && subscription?.trial_end)
    ? moment(subscription?.trial_end * 1000).format('MMMM Do')
    : null
  const currentPeriodEndDate = subscription?.status === 'active'
    ? moment(subscription?.current_period_end * 1000).format('MMMM Do')
    : null
  // @ts-ignore
  const hasCardEntered = !!subscription?.customer?.default_source;

  return (
    <div className='CustomerSummary'>
      <div className='body'>
        {(() => {
          if (subscription?.cancel_at_period_end) {
            return (
              <div className='statusTitle'>
                <strong style={{color: '#B90C0F'}}>CANCELLING</strong> on {currentPeriodEndDate || trialEndDate || ''}
              </div> 
            )
          }

          switch (subscription?.status) {
            case 'trialing':
              return (
                <div className='statusTitle'>
                  <strong>FREE TRIAL</strong> until {trialEndDate}
                </div>
              )

            case 'active':
              return (
                <div className='statusTitle'>
                  <strong>ACTIVE.</strong> Next payment date is {currentPeriodEndDate}
                </div>
              )

            case 'past_due':
              return (
                <div className='statusTitle'>
                  <strong>OVERDUE</strong> Make a payment to avoid interruption
                </div>
              )

            case 'canceled':
              return (
                <div className='statusTitle'>
                  <strong>CANCELED</strong> Due to non payment
                </div>
              )

            default:
              break;
          }
        })()}

      </div>

      { hasCardEntered
        ? <div className='cardIndicator good'>
          <p>Credit Card</p>
          <i className='mdi mdi-check' />
        </div>
        : <div className='cardIndicator bad'>
          <p>Credit Card</p>
          <i className='mdi mdi-close' />
        </div>
      }
    </div>
  );
}
