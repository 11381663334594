import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';

export type CancellingAtPeriodEndSubscription = Stripe.Subscription;
export type CancellingAtPeriodEndSubscriptions = CancellingAtPeriodEndSubscription[];

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED',
}

type CancellingAtPeriodEndState = {
  status: Status;
  data: CancellingAtPeriodEndSubscriptions;
}

const initialState: CancellingAtPeriodEndState = {
  status: Status.INITIAL,
  data: [],
};

const cancellingAtPeriodEndSlice = createSlice({
  name: 'cancellingAtPeriodEndSubscriptions',
  initialState: initialState,
  reducers: {
    updateCancellingAtPeriodEnd: (state, action: PayloadAction<{ subscriptions: CancellingAtPeriodEndSubscriptions }>) => {
      const { subscriptions } = action.payload;
      
      state.data = subscriptions;
      state.status = Status.UPDATED;
    },
    updateCancellingAtPeriodEndFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetCancellingAtPeriodEnd: (state) => {
      state.data = [];
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updateCancellingAtPeriodEnd,
  updateCancellingAtPeriodEndFailed,
  resetCancellingAtPeriodEnd,
} = cancellingAtPeriodEndSlice.actions

export default cancellingAtPeriodEndSlice.reducer;

