import React from 'react';
import moment from 'moment-timezone';
import Stripe from 'stripe';
import CalendarList from './CalendarList';
import CancellationItem from './CancellationItem';
import ScrollView from '../Page/ScrollView';
import {LiveIspProfile, LiveIspList} from '@reducers/liveIspList';
import {
  useCancelledSubscriptions,
  useLiveIspList,
} from '@hooks/redux';
import {formatDaysAgoLabel} from './utils';
import { isCancellation } from "@utils/subscriptions";
import './Cancellations.css';
import {StripeProductId} from "../../utils/subscriptions/stripeProducts";

type Subscription = Stripe.Subscription;

type DaysAgo = {
  daysAgo: number;
  label: string;
  items: DaysAgoItem[];
}

type DaysAgoItem = {
  id: string | null;
  subscription: Subscription;
  profile: LiveIspProfile;
}

type Props = {
  setFocusedISP: (ispId: string | null) => void;
  lastNDays: number;
}

export default function Cancellations ({ setFocusedISP, lastNDays }: Props) {
  const canceled = useCancelledSubscriptions();
  const liveISPList = useLiveIspList();

  const timeLimit = moment.tz('America/Los_Angeles').subtract(lastNDays, 'days').startOf('day').valueOf();

  const cancelations = canceled.filter(sub => isCancellation(sub, {timeLimit}));
  const trialCancellations = canceled.filter(sub => isCancellation(sub, {timeLimit, excludeCancelledOutsideTrial: true, includeCancelledWithinTrial: true }));

  const totalValue = cancelations.reduce((acc, subscription) => {
    const quantity  = subscription.items.data.find((item) => item.plan.product === StripeProductId.ROUTES)?.quantity

    return acc += ((quantity ?? 0) * 20);
  }, 0);

  const routes = canceled.reduce((acc, subscription) => {
    if (!subscription || !subscription.metadata) return acc;

    const { ISPID } = subscription.metadata;
    const profile = liveISPList[ISPID];

    if (!profile) return acc;

    for (const workAreaId in profile.WorkAreas) {
      if (profile.WorkAreas[workAreaId].paid === true) {
        acc.paid += 1;
      }

      acc.total += 1;
    }

    return acc;
  }, { paid: 0, total: 0 });

  return (
    <React.Fragment>
      <div className='cohort'>
        <p style={{ fontSize: '12px' }}>{`Cancellations in the last ${lastNDays} days.`}</p>

        <p style={{ fontSize: '12px' }}>
          <span style={{ margin: '0 5px', color: 'rgb(22, 142, 50)' }}>${totalValue}</span>
          <span style={{ margin: '0 5px' }}>
            <span style={{ color: '#006DAB' }}>{routes.paid}</span>
            {` / `}
            <span>{routes.total}</span>
          </span>
          <span style={{ margin: '0 5px' }}>{cancelations.length} ISP</span>
        </p>
      </div>

      <ScrollView direction='vertical' height='700px'>
        <CancellationList subscriptions={cancelations} liveISPList={liveISPList} setFocusedISP={setFocusedISP} />

        {!!trialCancellations.length &&
          <React.Fragment>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30 }}>
              <p style={{ color: '#888', fontWeight: 300 }}>Cancelled during trial</p>
            </div>
            <CancellationList subscriptions={trialCancellations} liveISPList={liveISPList} setFocusedISP={setFocusedISP} />
          </React.Fragment>
        }
      </ScrollView>
    </React.Fragment>
  );
}

type CancellationListProps = {
  subscriptions: Subscription[];
  liveISPList: LiveIspList;
  setFocusedISP: (ispId: string | null) => void;
}
function CancellationList ({ subscriptions, liveISPList, setFocusedISP }: CancellationListProps) {
  const now = Date.now();

  return (
    <CalendarList
      labelColor='#666'
      data={Object
        .values(subscriptions.reduce<Record<number, DaysAgo>>((acc, subscription) => {
        const { canceled_at, metadata } = subscription;
        const { ISPID } = metadata;
        const daysAgo = moment(now).diff(moment(canceled_at! * 1000), 'days');

        if (!acc.hasOwnProperty(daysAgo)) {
          const label = formatDaysAgoLabel(daysAgo);

          acc[daysAgo] = {
            daysAgo: daysAgo,
            label,
            items: []
          };
        }

        const profile = liveISPList[ISPID] || null;

        if (profile) {
          acc[daysAgo].items.push({
            id: ISPID || null,
            subscription: subscription,
            profile
          });
        }

        return acc;
      }, {}))}
      onItemClick={setFocusedISP}
      ListItem={CancellationItem}
    />
  )
}