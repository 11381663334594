import React from "react";
import moment from "moment-timezone";
import {RouteComponentProps} from 'react-router-dom';
import pr from '@packageroute/types-firebase';
import FirebaseLink from "../common/FirebaseLink";
import IspLink from "../common/IspLink";
import ApiTestTool from "../Isp/ApiTestTool";
import PageWrapper from "../Page/PageWrapper";
import PageTitle from "../Page/PageTitle";
import Section from "../Page/Section";
import UserProfile from "./UserProfile";
import UserDevices from "./UserDevices";
import UserIsps from "./UserIsps";
import UserStations from './UserStations';
import UserReferrals from './UserReferrals';
import EditUserISPs from './EditUserISPs';
import DeleteUser from './DeleteUser';
import StripeLink from '../common/StripeLink';
import Stripe from "stripe";
import IspSubscription from "../Payment/IspSubscription";
import withPermissions from "../Permissions/withPermissions";
import api, {SubscriptionData} from "../../utils/api";
import { titleize } from "../../utils/formatting";
import UserTrialControl from "./UserTrialControl";
import {PortalModal} from '../Page/Modal';
import ConfirmationModal from '../Page/ConfirmationModal';
import Button from '../Page/Button';
import "./User.css";

type AppUser = pr.users.User & { xidNodeData: { trialEndAt: number, trialStartedAt: number, uid: string }};
type ISPS = pr.users.user.ISPS;
type Profile = pr.isp.profile.Profile;
type WorkArea = pr.isp.profile.work_areas.WorkArea;
type Referral = pr.invite_codes.InviteCode;

type LoadedIsp = Profile & { id: string };
type InvitingUser = {
  first: string;
  last: string;
  uid: string;
}

type Props = {
  match: RouteComponentProps<{ fedExId: string}>['match'];
  history: RouteComponentProps<{ fedExId: string}>['history'];
  fetchUsers: (userIds: string[]) => void;
  users: any;
  activeStations: any;
  fetchActiveStations: any;
  liveISPList: any;
}

type State = {
  user: null | AppUser;
  isps: null | LoadedIsp[];
  referralUser: null | InvitingUser;
  referrals: null | Referral;
  displayEditIspsModal?: boolean;
  displayAddStripeCustomerAccountModal?: boolean;
  subscriptions: Record<string, SubscriptionData>;
}

class User extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      isps: null,
      referralUser: null,
      referrals: null,
      displayEditIspsModal: false,
      subscriptions: {},
    };

    this.lastLogin = this.lastLogin.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.displayAddStripeCustomerAccountModal = this.displayAddStripeCustomerAccountModal.bind(this);
    this.closeAddStripeCustomerAccountModal = this.closeAddStripeCustomerAccountModal.bind(this);
    this.confirmAddStripeCustomerAccount = this.confirmAddStripeCustomerAccount.bind(this);
  }

  componentDidMount () {
    this.loadUser();
  }

  componentDidUpdate (prevProps: Props) {
    const { fedExId } = this.props.match.params;

    if (prevProps.match.params.fedExId !== fedExId) {
      this.setState({
        user: null,
        isps: null,
        referralUser: null,
        referrals: null
      }, () => {
        this.loadUser();
      })
    }
  }

  async loadUser () {
    try {
      const { fedExId } = this.props.match.params || false;

      if (fedExId) {
        const res = await api.request<AppUser>(`Users/FedExId/${fedExId}`);
        const user = res.data;

        this.setState({ user }, () => {
          user.ISPS && this.loadIsps(user.ISPS);

          this.loadReferrals();
          this.loadReferralUser();
          this.loadSubscriptions();
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async reloadUser () {
    try {
      const { fedExId } = this.props.match.params || false;

      if (fedExId) {
        // If user is reloaded too quickly, then user record won't have
        // updated in time.
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const res = await api.request<AppUser>(`Users/FedExId/${fedExId}`);
        const user = res.data;

        this.setState({ user }, () => {
          user.ISPS && this.loadIsps(user.ISPS);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async loadIsps (isps: ISPS) {
    const loadedIsps: LoadedIsp[] = [];

    for (const firebaseId in isps) {
      const isp = isps[firebaseId];
      // need to fetch ISP db id from postgres for nav link to ISP details page
      // and merge that id with the up-to-date data from Firebase
      const dbIsp = await api.getCrmIdForFirebaseId(firebaseId);
      const profile = await api.getLatestIspProperty<Profile>(
        "profile",
        firebaseId
      );

      loadedIsps.push({ ...profile, id: dbIsp.id });
    }

    this.setState({ isps: loadedIsps });
  }

  async loadReferrals () {
    const { user } = this.state;
    const res = await api.request<Referral>(`referrals/${user?.uid ?? 'no_user'}`);
    const referrals: Referral = res.data || {};

    this.setState({ referrals }, () => {
      if (!referrals) return;

      const referredUsers: string[] = [];

      if (referrals.pendingInvites) {
        for (const uid in referrals.pendingInvites) {
          if (!referredUsers.includes(uid)) {
            referredUsers.push(uid);
          }
        }
      }

      if (referrals.completedInvites) {
        for (const uid in referrals.completedInvites) {
          if (!referredUsers.includes(uid)) {
            referredUsers.push(uid);
          }
        }
      }

      this.props.fetchUsers(referredUsers);
    });
  }

  async loadReferralUser () {
    const { user } = this.state;

    // @ts-ignore
    if (!user || !user.invitedBy) return null;

    // @ts-ignore
    const res = await api.request<InvitingUser>(`users/invite-code/${user.invitedBy}`);
    const referralUser = res.data;

    this.setState({ referralUser: referralUser || null });
  }

  lastLogin(lastLogin: string | number) {
    return moment(lastLogin).calendar(null, {
      sameDay: "[Today], hh:mm a",
      lastDay: "[Yesterday], hh:mm a",
      lastWeek: "[Last] dddd",
      sameElse: "M-DD-YYYY"
    })
  }

  userWorkAreas(user: AppUser | null, isps: LoadedIsp[] | null = []) {
    const workAreas: Record<string, WorkArea> = {};

    if (!user || !isps) return workAreas;

    for (const isp of isps) {
      const workAreasList = Object.keys(isp.WorkAreas || {});
      for (const wa of workAreasList) {
        // driver FedExId may not be a string, must cast to string
        if (String(isp.WorkAreas[wa].driver) === String(user.FedExId)) {
          workAreas[wa] = isp.WorkAreas[wa];
        }
      }
    }

    return workAreas;
  }

  async loadSubscriptions () {
    const { user } = this.state;

    if (!user || user.Type === 'driver' || !user.customerId) return;

    this.loadSubscription(user.uid);
  }

  async loadSubscription (uid: string) {
    const subscriptions = await api.getUserSubscriptionData(uid);

    this.setState({ subscriptions });
  }

  displayAddStripeCustomerAccountModal () {
    this.setState({ displayAddStripeCustomerAccountModal: true });
  }

  closeAddStripeCustomerAccountModal () {
    this.setState({ displayAddStripeCustomerAccountModal: false });
  }

  async confirmAddStripeCustomerAccount () {
    try {
      const { user } = this.state;

      if (user) {
        await api.createUserStripeCustomerAccount(user.uid);

        this.closeAddStripeCustomerAccountModal();
        this.reloadUser();
      }
    } catch (err) {
      console.error(err);
    }
  }

  render () {
    const { user, isps, referralUser, referrals, displayEditIspsModal, subscriptions, displayAddStripeCustomerAccountModal } = this.state;
    const { users, liveISPList } = this.props;

    const workAreas = this.userWorkAreas(user, isps || []);

    return (
      <div className="User">
        <PageWrapper>
          <PageTitle
            secondTitle={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 300,
                    display: "flex",
                  }}
                >
                  {isps
                    ? isps.map((isp) => {
                        return (
                          <IspLink
                            key={isp.ISPID}
                            isp={isp}
                            activeIsp={user?.ISP === isp.ISPID}
                          />
                        );
                      })
                    : null}
                </span>
              </div>
            }
            rightContent={
              user && user.metadata ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {user.metadata.lastLogin ? (
                    <p style={{ marginRight: "25px" }}>
                      Last login at: {this.lastLogin(user.metadata.lastLogin)}
                    </p>
                  ) : null}
                </div>
              ) : null
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {user && user.first ? titleize(user.first) : ""}{" "}
              {user && user.last ? titleize(user.last) : ""}
              <FirebaseLink
                path={user && `Users/${user.uid}`}
              />
            </div>
          </PageTitle>

          <Section title="Details">
            <UserProfile
              user={user}
              workAreas={workAreas}
              referreralUser={referralUser}
            />
          </Section>

          <Section title="FedExId">
            <ApiTestTool
              contactId={this.props.match.params.fedExId}
              contactName={
                (user && user.first ? titleize(user.first) : "") +
                " " +
                (user && user.last ? titleize(user.last) : "")
              }
            />
          </Section>

          {!!user && (
            <React.Fragment>
              <Section
                title="ISPs"
                bottomContent={
                  <div className="edit-isps" style={{ marginTop: 5 }}>
                    <Button
                      onClick={() => {
                        this.setState({ displayEditIspsModal: true });
                      }}
                      color="#006DAB"
                    >
                      Edit ISPs
                    </Button>
                  </div>
                }
              >
                <UserIsps
                  user={user}
                  isps={isps ? isps : []}
                  lastActiveIsp={user?.ISP}
                  history={this.props.history}
                />
              </Section>

              <PortalModal
                visible={displayEditIspsModal}
                centerFullScreen
                isMobile={false}
                onRequestClose={() => {
                  this.setState({ displayEditIspsModal: false });
                }}
              >
                <EditUserISPs
                  liveISPList={liveISPList}
                  user={user}
                  onEdit={() => {
                    this.reloadUser();
                    this.setState({ displayEditIspsModal: false });
                  }}
                />
              </PortalModal>

              <ConfirmationModal
                message='Create Stripe Customer Account?'
                description='Creates stripe customer account and attaches to user.'
                confirmLabel='Create'
                display={displayAddStripeCustomerAccountModal}
                close={this.closeAddStripeCustomerAccountModal}
                confirm={this.confirmAddStripeCustomerAccount}
              />
            </React.Fragment>
          )}

          <Section title="Stations">
            <UserStations
              activeStations={this.props.activeStations}
              fetchActiveStations={this.props.fetchActiveStations}
              user={user}
              isps={isps || []}
            />
          </Section>

          <Section
            title="Subscriptions"
            bottomContent={
              <div
                style={{
                  padding: 5,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!!user?.customerId ? (
                  <StripeLink type="cus" id={user?.customerId} showTitle />
                ) : (
                  <Button
                    onClick={this.displayAddStripeCustomerAccountModal}
                    color="#006DAB"
                  >
                    Add Stripe Customer Account
                  </Button>
                )}
              </div>
            }
          >
            {Object.keys(user?.ISPS || {}).map((ispId, i) => {
              const profile = (isps || []).find(
                (profile) => profile.ISPID === ispId
              );

              return (
                <IspSubscription
                  showISPName
                  ISPID={ispId}
                  subscriptionData={subscriptions[ispId]}
                  loadSubscription={() => this.loadSubscription(ispId)}
                  liveISPList={liveISPList}
                  latestProfile={profile}
                />
              );
            })}
          </Section>

          <Section title="Referrals">
            <UserReferrals
              referrals={referrals}
              liveISPList={this.props.liveISPList}
              users={users}
              history={this.props.history}
            />
          </Section>

          <Section title="Trial">
            <UserTrialControl user={user} loadUser={this.loadUser} />
          </Section>

          <Section title="Devices">
            <UserDevices
              devices={user && user.devices}
              lastActiveDevice={user && user.metadata}
            />
          </Section>

          {!!user &&
            <Section title="Delete User">
              <DeleteUser
                userUid={user.uid}
                onDeletion={() => this.reloadUser()}
              />
            </Section>
          }
        </PageWrapper>
      </div>
    );
  }
}

export default withPermissions(User);
